import React, {useEffect, useState} from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
    Avatar,
    Box,
    Button,
    FormGroup,
    FormControlLabel,
    Grid,
    Paper,
    Checkbox,
    TextField,
    Typography,
    FormHelperText
} from '@mui/material'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import Page from '../../../components/public/Page'
import usePosition from '../../../hooks/usePosition'
import {customerSignup} from '../../../actions'
import bgImg from './images/reg-bg.jpg'
import {ORGANIZATION} from '../../../constants'

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 300,
        maxWidth: 600,
        margin: 'auto'
    },
    paper: {
        backgroundColor: theme.palette.background.dark,
        padding: '1rem 1rem'
        // backgroundColor: theme.palette.mode === 'light' ? '#f4f6f8' : theme.palette.grey[700],
    },
    grid__main: {
        width: '100%'
    },
    topBgImg: {
        backgroundImage: `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 200,
        width: '100%'
    },
    grid__gap: {
        minHeight: 80
    },
    paper__form: {
        width: '95%',
        margin: '0 auto'
    },
    avatar: {
        width: 100,
        height: 100
    },
    form__title: {
        padding: '20px 0px'
    },
    form__subtitle: {
        margin: '15px 0',
        fontWeight: 'bold'
    },
    submit: {},
    textArea: {
        marginTop: 10,
        marginBottom: 20
    },
    footer: {
        paddingTop: 30,
        paddingBottom: 10
    },
    test: {
        // color: 'red'
    }
}))
const schema = yup.object().shape({
    firstName: yup.string().required('First Name is required').trim(),
    lastName: yup.string().required('Last Name is required').trim(),
    email: yup.string().email().required().trim().lowercase(),
    phone: yup.string().required().min(9).trim(),
    class: yup.array().min(1).max(3)
})

function NewCustomerRegisterView({customerSignup, customer}) {
    const [status, setStatus] = useState(false)
    const {id} = useParams()
    const classes = useStyles()
    const [position] = usePosition()
    const {register, errors, handleSubmit, isSubmitting} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    const handleCustomerSubmit = formData => {
        // client isn't a mobile then position is null
        // console.log({formData, position, id});
        // reconfig the object
        // in database missing the column of q1 to q5
        const dataObj = {
            salesUid: id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            wechat: formData.wechat,
            regCoordinate: position,
            regInfo: formData.class.join('|'),
            regQuestion: formData.otherQuestion
        }
        customerSignup(dataObj)
    }

    useEffect(() => {
        if (customer.success !== undefined) {
            if (customer.success) {
                setStatus(true)
                // console.log("ok");
            } else {
                // console.log("no");
                return false
            }
        }
        return false
    }, [customer.success])

    return (
        <Page className={classes.root} title="Start your journey with us!">
            <Paper className={classes.paper}>
                <Grid container className={classes.grid__main} direction="column">
                    <Grid item className={classes.topBgImg}>
                        <Box position="relative" top={160} left={20} display="flex" alignItems="flex-end">
                            <Avatar alt="Person" className={classes.avatar} src="/static/images/avatars/avatar_2.png" />
                            <Box marginLeft="20px">
                                <Typography variant="overline" color="textSecondary" className={classes.test}>
                                    Course Consultants
                                </Typography>
                                <Typography variant="h4" color="textPrimary">
                                    FAAG
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item className={classes.grid__gap} />
                    <Grid item>
                        <div className={classes.paper__form}>
                            <Typography className={classes.form__title} variant="h2" align="center">
                                {status ? 'Registered Successfully!' : 'Get Started for Trial Course'}
                            </Typography>
                            {status ? (
                                ''
                            ) : (
                                <form noValidate onSubmit={handleSubmit(handleCustomerSubmit)}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="firstName"
                                        id="firstName"
                                        label="First Name *"
                                        autoComplete="First Name"
                                        autoFocus
                                        inputRef={register}
                                        error={Boolean(errors.firstName?.message)}
                                        helperText={errors.firstName?.message}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="lastName"
                                        id="lastName"
                                        label="Last Name *"
                                        autoComplete="Last Name"
                                        inputRef={register}
                                        error={Boolean(errors.lastName?.message)}
                                        helperText={errors.lastName?.message}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="email"
                                        id="email"
                                        label="Email *"
                                        autoComplete="email"
                                        inputRef={register}
                                        error={Boolean(errors.email?.message)}
                                        helperText={errors.email?.message}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="phone"
                                        id="phone"
                                        label="Phone *"
                                        autoComplete="phone"
                                        inputRef={register}
                                        error={Boolean(errors.phone?.message)}
                                        helperText={errors.phone?.message}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="wechat"
                                        id="wechat"
                                        label="WeChat"
                                        autoComplete="wechat"
                                        inputRef={register}
                                    />
                                    <Typography variant="body1" className={classes.form__subtitle}>
                                        Please select the course you are interested in
                                    </Typography>
                                    <FormHelperText error={Boolean(errors.class?.message)}>
                                        {errors.class?.message}
                                    </FormHelperText>
                                    <FormGroup row style={{margin: '0 10px'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="class"
                                                            color="primary"
                                                            value="软件/互联网开发服务"
                                                            inputRef={register}
                                                        />
                                                    }
                                                    label="软件/互联网开发服务"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name="class"
                                                            color="secondary"
                                                            value="我还不确定"
                                                            inputRef={register}
                                                        />
                                                    }
                                                    label="我还不确定"
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>

                                    <Typography variant="body1" className={classes.form__subtitle}>
                                        Leave your questions, and we will answer them as quickly as possible!
                                    </Typography>

                                    <FormGroup row style={{margin: '0 10px'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={<Checkbox name="q5" color="primary" inputRef={register} />}
                                                    label="技术支持还有哪些相应的服务？"
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>

                                    <TextField
                                        id="otherQuestion"
                                        name="otherQuestion"
                                        fullWidth
                                        inputRef={register}
                                        className={classes.textArea}
                                        multiline
                                        placeholder="Any other questions, please feel free to let us know."
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.submit}
                                    >
                                        Submit
                                    </Button>
                                </form>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <footer className={classes.footer}>
                    <Typography variant="body2" align="center" color="textSecondary">
                        Founded In 2018, {ORGANIZATION.name} and focuses On IT Solutions and AI Agent development and
                        deployment
                    </Typography>
                </footer>
            </Paper>
        </Page>
    )
}

export default connect(({customer}) => ({customer}), {customerSignup})(NewCustomerRegisterView)
