import * as yup from 'yup'
import {ORGANIZATION} from '../../../constants'

export const mbToBytes = size => size * 1024 * 1024

export const VALID_INPUT_TYPES = {
    text: 'text',
    textarea: 'textarea',
    select: 'select',
    radio: 'radio',
    check: 'check',
    file: 'file',
    address: 'address'
}

export const DEFAULT_SELECT_VALUE = '__NONE'
export const FORM_UNIQUE_TAGS_TEXT = [
    {value: 'firstname', name: 'First Name'},
    {value: 'lastname', name: 'Last Name'},
    {value: 'phone', name: 'Phone'},
    {value: 'email', name: 'Email'},
    {value: 'wechat', name: 'WeChat'},
    {value: 'address', name: 'Address'},
    {value: 'city', name: 'City'},
    {value: 'country', name: 'Country'},
    {value: 'postal', name: 'Postal'},
    {value: 'employer', name: 'Employer'},
    {value: 'jobposition', name: 'Job Position'},
    {value: 'jobsource', name: 'Job Source'},
    {value: 'url', name: 'URL'},
    {value: 'salary', name: 'Salary'},
    {value: 'workhours', name: 'Work Hours'},
    {value: 'university', name: 'University'}
]
export const FORM_UNIQUE_TAGS_AREA = [
    {value: 'jobdescription', name: 'Job Description'},
    {value: 'jobrequirement', name: 'Job Requirement'}
]
export const FORM_UNIQUE_TAGS_FILE = [{value: 'resume', name: 'Resume'}]

export const DEFAULT_FORM_COMPLETE_MESSAGES = {
    formCompleteTitle: 'Thank you for your interest',
    formCompleteDescription: `Your form has been submitted, please do not submit multiple times:)\\nShould there be any other concerns, please email us at: info@${ORGANIZATION.url}`,
    formCompleteRedirectNotice: `Click to return to ${ORGANIZATION.name} Official Website`,
    formCompleteRedirectLink: `https://${ORGANIZATION.url}`
}

export const COMMON_PATTERNS = {
    clear: '',
    number: '^\\d+$',
    letter: '^[a-zA-Z]*$',
    alphanumeric: '^[a-zA-Z0-9 ]*$',
    email: '^\\S+@\\S+\\.\\S+$',
    phone: '^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$',
    date: '^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](18|19|20|21|22)\\d\\d$',
    website:
        '^https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)'
}

export const COMMON_FILETYPES = {
    images: 'image/*',
    pdf: 'application/pdf',
    doc: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export const FormCreatorValidator = {
    inputType: yup.string(),
    label: yup.string().when('inputType', {
        is: inputType => inputType !== 'address',
        then: yup
            .string()
            .required('Required')
            .min(1, 'Label must be at least 1 characters')
            .max(255, 'Label must be less than 255 characters')
            .trim()
    }),
    description: yup.lazy(value =>
        !value
            ? yup.string()
            : yup
                  .string()
                  .min(1, 'Description must be at least 1 characters')
                  .max(255, 'Description must be less than 255 characters')
                  .trim()
    ),
    minLength: yup.lazy(value =>
        value === ''
            ? yup.string()
            : yup
                  .number()
                  .required('Required')
                  .typeError('Min length must be a number')
                  .moreThan(-1, 'Min length must be positive')
    ),
    maxLength: yup.lazy(value =>
        value === ''
            ? yup.string()
            : yup
                  .number()
                  .required('Required')
                  .typeError('Max length must be a number')
                  .moreThan(0, 'Max length must be longer than 1')
    ),
    values: yup.string('Required').min(1, 'Description must be at least 1 character').trim(),
    patternMessage: yup.lazy(value =>
        !value
            ? yup.string()
            : yup
                  .string()
                  .min(3, 'Pattern must be at least 3 character')
                  .max(255, 'Pattern must be less than 255 characters')
                  .trim()
    ),
    fileType: yup.lazy(value =>
        !value
            ? yup.string()
            : yup
                  .string()
                  .min(3, 'File type must be at least 3 character')
                  .max(200, 'File type must be less than 200 characters')
                  .trim()
    ),
    addressLabel: yup.string().when('isAddressAdded', {
        is: true,
        then: yup
            .string()
            .required('required')
            .min(1, 'Label must be at least 1 characters')
            .max(255, 'Label must be less than 255 characters')
            .trim()
    }),
    countryLabel: yup.string().when('isCountryAdded', {
        is: true,
        then: yup
            .string()
            .required('required')
            .min(1, 'Label must be at least 1 characters')
            .max(255, 'Label must be less than 255 characters')
            .trim()
    }),
    cityLabel: yup.string().when('isCityAdded', {
        is: true,
        then: yup
            .string()
            .required('required')
            .min(1, 'Label must be at least 1 characters')
            .max(255, 'Label must be less than 255 characters')
            .trim()
    })
}

export const FormCreatorHeadValidator = {
    formName: yup
        .string()
        .required('Required')
        .min(2, 'Form name must be at least 2 characters')
        .max(255, 'Form name must be less than 255 characters')
        .trim(),
    formDescription: yup
        .string()
        .required('Required')
        .min(2, 'Form description must be at least 2 characters')
        .max(255, 'Form description must be less than 255 characters')
        .trim(),
    formSlug: yup
        .string()
        .required('Required')
        .min(2, 'Form slug must be at least 2 characters')
        .max(255, 'Form slug must be less than 255 characters')
        .trim(),
    formCompleteTitle: yup
        .string()
        .required('Required')
        .min(2, 'Form completion title be at least 2 characters')
        .max(255, 'Form completion title must be less than 255 characters')
        .trim(),
    formCompleteDescription: yup
        .string()
        .required('Required')
        .min(2, 'Form completion message be at least 2 characters')
        .max(255, 'Form completion message must be less than 255 characters')
        .trim(),
    formCompleteRedirectNotice: yup
        .string()
        .required('Required')
        .min(2, 'Link message must be at least 2 characters')
        .max(255, 'Link message must must be less than 255 characters')
        .trim(),
    formCompleteRedirectLink: yup
        .string()
        .required('Required')
        .min(2, 'URL must be at least 2 characters')
        .max(255, 'URL must be less than 255 characters')
        .matches(new RegExp(COMMON_PATTERNS.website), 'URL must start with http or https')
        .trim()
}

export const WRONG_INPUT_SYNTAX_MESSAGE = 'Not a valid input or contains wrong format'

export const FORM_BUILDER_HEAD_MODE = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    CLONE: 'CLONE'
}
