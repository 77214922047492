import React from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import {ORGANIZATION} from '../../constants'

function CopyRight() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" noWrap>
            {'Copyright © '}
            <Link color="inherit" href="#" target="_blank">
                {ORGANIZATION.name}
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    )
}

export default CopyRight
