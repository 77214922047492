import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    InputAdornment,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
    Tooltip,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel
} from '@mui/material'
import {debounce, cloneDeep} from 'lodash'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Autocomplete from '@mui/material/Autocomplete'
import {CheckCircle, Clipboard, XCircle} from 'react-feather'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import ClassChangeHistory from '../../training/class/ClassChangeHistory'
import {
    customerActionsType,
    fetchEmployees,
    actEmailCheck,
    updateCustomer,
    fetchCustomerProducts,
    fetchAllRoles
} from '../../../actions'
import {fetchClassTransferByCustomerId} from '../../../actions/class/classActions'

import SubmitButton from '../../../components/mui/button/SubmitButton'
import EditCustomerTab from './EditCustomerTab'
import {noteField, textField, useEditCustomerStyle} from '../customerData'
import AddressAutoComplete from '../../../components/api/AddressAutoComplete'
import {CUSTOMER_VALIDATOR, validateEmail} from './customerHelper'
import history from '../../../utils/history'
import {copyToClipboard} from '../../../utils/Scripts'
import {copyBoardStyle, ORGANIZATION, USER_ROLE_ID} from '../../../constants'

const schema = yup.object().shape(CUSTOMER_VALIDATOR)

function EditCustomer({
    currentCustomer,
    updateCustomer,
    fetchCustomerProducts,
    products,
    allUserRoles,
    employees,
    fetchEmployees,
    actEmailCheck,
    error,
    isSearching
}) {
    const [readOnly, setReadOnly] = useState(textField.map(() => true))

    const classes = useEditCustomerStyle()
    const dispatch = useDispatch()
    const [isSaving, setIsSaving] = useState(false)

    // default select student
    const [selectedCat, setSelectedCat] = useState([USER_ROLE_ID.STUDENT])

    const [customersAndEmployees, setCustomerAndEmployees] = useState([])
    const [repErrors, setRepErrors] = useState('')
    const [salesId, setSalesId] = useState('')
    const [currentSales, setCurrentSales] = useState(null)
    const [currentInputSale, setCurrentInputSale] = useState('')
    const [productSelect, setProductSelect] = useState([])
    const [productError, setProductError] = useState('')
    // const [currentCustomerDisplayNote, setCurrentCustomerDisplayNote] = useState({
    //     ...currentCustomer, notes: currentCustomer?.notes[0]?.notes
    // });
    const currentCustomerDisplayNote = {...currentCustomer, notes: currentCustomer?.notes[0]?.notes}

    const loadData = () => {
        // only attempt to fetch roles if there are no roles
        if (allUserRoles.length <= 0) {
            fetchAllRoles()(dispatch)
        }
        fetchClassTransferByCustomerId(currentCustomer?.id)(dispatch)
    }
    useEffect(loadData, [dispatch, allUserRoles])

    const {register, errors, setError, handleSubmit, setValue} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange',
        defaultValues: currentCustomerDisplayNote
    })

    const handleSaveSubmit = formData => {
        const tempCustomer = cloneDeep(currentCustomer)
        Object.keys(formData).forEach(item => {
            tempCustomer[item] = formData[item]
            return null
        })
        let regInfo = ''
        if (productSelect) {
            productSelect.forEach(value => {
                regInfo = regInfo.concat(value, '|')
                return null
            })
        }

        if (!tempCustomer.isStaff && !tempCustomer.isCustomer) {
            return setError('category', {type: 'manual', message: 'At least one is required'})
        }

        tempCustomer.regInfo = regInfo
        tempCustomer.userId = tempCustomer.id

        // Set User Role
        tempCustomer.roleIds = selectedCat

        const tempReadOnly = readOnly.map(() => true)
        if (salesId) {
            setRepErrors('')
            tempCustomer.sales = salesId
            setReadOnly(tempReadOnly)
            if (productSelect.length <= 3 && productSelect.length >= 1) {
                updateCustomer(tempCustomer, afterSaving, catchError)
                setIsSaving(true)
            } else {
                setProductError('Must select 1-3 products')
            }
        } else {
            setRepErrors('This field must be selected')
        }
        return null
    }

    const catchError = () => {
        setIsSaving(false)
    }

    const afterSaving = () => {
        setIsSaving(false)
    }

    useEffect(() => {
        fetchCustomerProducts()
        fetchEmployees()
    }, [fetchCustomerProducts, fetchEmployees])

    useEffect(() => {
        // TODO: Set roles on edit
        if (currentCustomer.roleIds) {
            setSelectedCat(currentCustomer.roleIds.map(item => parseInt(item, 10)))
        }
        if (currentCustomer.regInfo) {
            const array = currentCustomer.regInfo.split('|')
            array.pop()
            setProductSelect(array)
        }
    }, [currentCustomer])

    useEffect(() => {
        if (employees && currentCustomer) {
            const employeesAndCustomers = []
            employees.forEach(employee => {
                const NameString = `${employee.id}. ${employee.firstName} ${employee.lastName}`
                if (employee.isActive) {
                    employeesAndCustomers.push({NameString, id: employee.id})
                }
            })

            setCustomerAndEmployees(employeesAndCustomers)

            const index = employeesAndCustomers.findIndex(item => item.id === currentCustomer.sales?.id)
            if (index >= 0) {
                setCurrentInputSale(employeesAndCustomers[index].NameString)
                setSalesId(currentCustomer.sales.id)
            }
        }
    }, [employees, currentCustomer])

    const handleAutoCompleteChange = (e, v) => {
        if (v) {
            setSalesId(v.id)
            setCurrentSales(v)
            setRepErrors('')
        } else {
            setSalesId('')
            setRepErrors('This field must be selected')
        }
    }

    const handleAddressCallback = value => {
        setValue('city', value.city)
        setValue('country', value.country)
    }

    const handleChange = event => {
        setProductSelect(event.target.value)
        if (event.target.value.length <= 3 && event.target.value.length >= 1) {
            setProductError('')
        }
    }

    const changeHandler = debounce(val => {
        if (!val || !validateEmail(val)) {
            dispatch({type: customerActionsType.ContinueCreate})
        }
        if (val && validateEmail(val)) {
            actEmailCheck(val, currentCustomer.id)
        }
    }, 500)

    const onCopy = targetName => {
        copyToClipboard(dispatch, currentCustomer[targetName])
    }

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Paper className={classes.paper__form}>
                    <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
                        <Grid container>
                            <Grid item>
                                <Typography className={classes.form__title} variant="h2" align="left">
                                    {currentCustomer.firstName.concat(' ', currentCustomer.lastName)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    margin="normal"
                                    name="company"
                                    id="company"
                                    label="From"
                                    inputProps={{readOnly: true, style: {cursor: 'default'}}}
                                    className={classes.text}
                                    defaultValue={ORGANIZATION.name}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        className={classes.repSelector}
                                        noOptionsText="There are no matching employees and customers"
                                        options={customersAndEmployees}
                                        getOptionLabel={option => (option ? option.NameString : '')}
                                        inputValue={currentInputSale}
                                        value={currentSales}
                                        onChange={(e, v) => {
                                            handleAutoCompleteChange(e, v)
                                        }}
                                        onInputChange={(e, v) => {
                                            if (e && e.type !== 'blur') {
                                                setCurrentInputSale(v)
                                            }
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                text="Select a customer or Employees..."
                                                label="Rep"
                                            />
                                        )}
                                    />
                                    <FormHelperText error={repErrors !== ''}>
                                        {repErrors !== '' ? repErrors : ''}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justifyContent="space-between">
                            {textField.map((item, index) => {
                                if (item.name === 'address') {
                                    return (
                                        <Grid item xs={12} sm={6} key={index}>
                                            <AddressAutoComplete
                                                label={`${item.label} *`}
                                                className={classes.text}
                                                name={item.name}
                                                helperText={errors[item.name]?.message}
                                                error={Boolean(errors[item.name]?.message)}
                                                TextComponent={TextField}
                                                handleAddressCallBack={handleAddressCallback}
                                                register={register}
                                                currentAddress={currentCustomer.address}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Tooltip title="Copy Form Value" placement="top">
                                                                <IconButton
                                                                    color="primary"
                                                                    size="small"
                                                                    component="span"
                                                                    onClick={() => onCopy(item.name)}
                                                                >
                                                                    <Clipboard {...copyBoardStyle} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            {readOnly[index] ? null : (
                                                <span>
                                                    <Button type="submit">
                                                        <CheckCircle />
                                                    </Button>
                                                    <Button>
                                                        <XCircle />
                                                    </Button>
                                                </span>
                                            )}
                                        </Grid>
                                    )
                                }
                                if (item.name === 'email') {
                                    return (
                                        <Grid item xs={12} sm={6} key={index}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                margin="normal"
                                                name={item.name}
                                                id={item.name}
                                                label={`${item.label} *`}
                                                autoComplete="off"
                                                error={Boolean(errors[item.name]?.message)}
                                                helperText={errors[item.name]?.message}
                                                className={classes.text}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                inputRef={register}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {isSearching ? <CircularProgress size="20px" /> : ''}
                                                        </InputAdornment>
                                                    ),
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Tooltip title="Copy Form Value" placement="top">
                                                                <IconButton
                                                                    color="primary"
                                                                    size="small"
                                                                    component="span"
                                                                    onClick={() => onCopy(item.name)}
                                                                >
                                                                    <Clipboard {...copyBoardStyle} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={e => changeHandler(e.target.value)}
                                            />
                                            {/* {readOnly[index] ? null : <span><Button type='submit'><CheckCircle/></Button><Button><XCircle/></Button></span>} */}
                                            <span style={{color: '#F44336', fontSize: '12px'}}>{error}</span>
                                        </Grid>
                                    )
                                }
                                return (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            margin="normal"
                                            name={item.name}
                                            id={item.name}
                                            label={
                                                item.name === 'gmail' ||
                                                item.name === 'gitName' ||
                                                item.name === 'zipcode' ||
                                                item.name === 'wechat'
                                                    ? `${item.label} `
                                                    : `${item.label} *`
                                            }
                                            autoComplete="off"
                                            inputRef={register}
                                            error={Boolean(errors[item.name]?.message)}
                                            helperText={errors[item.name]?.message}
                                            className={classes.text}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Tooltip title="Copy Form Value" placement="top">
                                                            <IconButton
                                                                color="primary"
                                                                size="small"
                                                                component="span"
                                                                onClick={() => onCopy(item.name)}
                                                            >
                                                                <Clipboard {...copyBoardStyle} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        {readOnly[index] ? null : (
                                            <span>
                                                <Button type="submit">
                                                    <CheckCircle />
                                                </Button>
                                                <Button>
                                                    <XCircle />
                                                </Button>
                                            </span>
                                        )}
                                    </Grid>
                                )
                            })}

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth className={classes.categorySelector}>
                                    <InputLabel>Role</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedCat}
                                        onChange={e => {
                                            setSelectedCat(e.target.value)
                                        }}
                                        renderValue={selected => (
                                            <div className={classes.chips}>
                                                {selected.map((value, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={
                                                            allUserRoles.find(
                                                                role => role.id.toString() === value.toString()
                                                            )?.name || 'Unknown'
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    >
                                        {allUserRoles.map(item => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth className={classes.categorySelector}>
                                    <InputLabel>Please Select Products</InputLabel>
                                    <Select
                                        multiple
                                        value={productSelect}
                                        renderValue={selected => (
                                            <div className={classes.chips}>
                                                {selected.map((value, index) => (
                                                    <Chip key={index} label={value} />
                                                ))}
                                            </div>
                                        )}
                                        onChange={handleChange}
                                        inputRef={register}
                                        name="class"
                                        id="class"
                                    >
                                        {products.map((item, index) => (
                                            <MenuItem key={index} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText error={Boolean(productError)}>{productError}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <ClassChangeHistory customerId={currentCustomer?.id} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                                    <FormLabel component="legend" required error={Boolean(errors.category?.message)}>
                                        User Category
                                    </FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="isStaff"
                                                    defaultChecked={currentCustomer?.isStaff}
                                                    inputRef={register}
                                                />
                                            }
                                            label="Staff"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name="isCustomer"
                                                    defaultChecked={currentCustomer?.isCustomer}
                                                    inputRef={register}
                                                />
                                            }
                                            label="Customer"
                                        />
                                    </FormGroup>
                                    <FormHelperText error={Boolean(errors.category?.message)}>
                                        {errors.category?.message
                                            ? errors.category?.message
                                            : 'At least one is required'}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            {/* note */}
                            {noteField.map((item, index) => (
                                <Grid item xs={12} sm={12} key={index}>
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            size="small"
                                            fullWidth
                                            margin="normal"
                                            name={item.name}
                                            id={item.name}
                                            label={`${item.label}`}
                                            autoComplete="off"
                                            inputRef={register}
                                            error={Boolean(errors[item.name]?.message)}
                                            helperText={errors[item.name]?.message}
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            className={classes.input}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                        {readOnly[index] ? null : (
                                            <span>
                                                <Button type="submit">
                                                    <CheckCircle />
                                                </Button>
                                                <Button>
                                                    <XCircle />
                                                </Button>
                                            </span>
                                        )}
                                    </FormControl>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container spacing={2} style={{marginTop: '40px'}}>
                            <Grid item sm={3}>
                                <SubmitButton
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    isSaving={isSaving}
                                    type="submit"
                                    text="Save"
                                    isSavingText="Saving"
                                />
                            </Grid>
                            <Grid item sm={3}>
                                <SubmitButton
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    isSaving={isSaving}
                                    text="Cancel"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>

            <EditCustomerTab />
        </Grid>
    )
}

const mapStateToProps = state => ({
    sales: state.auth.user,
    currentCustomer: state.customer?.currentCustomer,
    products: state.customer.product ? state.customer.product.filter(pr => pr.isActive) : [],
    error: state.customer?.error,
    isSearching: state.customer?.isSearching,
    employees: state.employee?.employees,
    allUserRoles: state.customer.allUserRoles
})

export default connect(mapStateToProps, {
    updateCustomer,
    fetchCustomerProducts,
    fetchEmployees,
    actEmailCheck
})(EditCustomer)
