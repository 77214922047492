import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel
} from '@mui/material'
import {debounce} from 'lodash'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {CheckCircle, XCircle} from 'react-feather'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import {useNavigate} from 'react-router-dom'
import {
    actEmailCheck,
    addCustomer,
    customerActionsType,
    fetchAllRoles,
    fetchCustomerProducts,
    fetchEmployees,
    sendErrorMessage
} from '../../../actions'
import WaitingOnly from '../../../components/public/WaitingOnly'
import SubmitButton from '../../../components/mui/button/SubmitButton'
import localStorage from '../../../utils/LocalStorage'
import {LS_KEY_CREATE_CUSTOMER} from '../../forms/formData/formDataHelper'
import {noteField, textField, useEditCustomerStyle} from '../customerData'
import AddressAutoComplete from '../../../components/api/AddressAutoComplete'
import {CUSTOMER_VALIDATOR, validateEmail} from './customerHelper'
import history from '../../../utils/history'
import {ORGANIZATION, USER_ROLE_ID} from '../../../constants'

const schema = yup.object().shape(CUSTOMER_VALIDATOR)

function AddCustomer() {
    const navigate = useNavigate()
    const readOnly = textField.map(() => true)
    // const sales = useSelector(state => state.auth.user)
    const products = useSelector(state =>
        state.customer.product ? state.customer.product.filter(pr => pr.isActive) : []
    )
    const error = useSelector(state => state.customer?.error)
    const isSearching = useSelector(state => state.customer?.isSearching)
    const employees = useSelector(state => state.employee?.employees)
    const allUserRoles = useSelector(state => state.customer.allUserRoles)

    const dispatch = useDispatch()
    const classes = useEditCustomerStyle()
    const [isSaving, setIsSaving] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isEmployeeLoaded, setIsEmployeeLoaded] = useState(false)
    const [isFormDataLoaded, setIsFormDataLoaded] = useState(false)
    const [selectedCat, setSelectedCat] = useState([USER_ROLE_ID.STUDENT])
    const [tempResume, setTempResume] = useState(null)
    const [tempAddress, setTempAddress] = useState('')
    // const [defaultValues, setDefaultValues] = useState({
    //     firstName: '', lastName: '', nickName: '', email: '', phone: '', gmail: '', gitName: '', address: '', city: '',
    //     country: '', zipcode: '', class: '', regSource: '', sales: '', repName: null, notes: []
    // });

    const defaultValues = {
        firstName: '',
        lastName: '',
        nickName: '',
        email: '',
        phone: '',
        gmail: '',
        gitName: '',
        address: '',
        city: '',
        country: '',
        zipcode: '',
        class: '',
        regSource: '',
        sales: '',
        repName: null,
        notes: []
    }
    const [customersAndEmployees, setCustomerAndEmployees] = useState([])
    const [repInput, setRepInput] = useState('')
    const [repErrors, setRepErrors] = useState('')
    const [salesId, setSalesId] = useState('')
    const [productSelect, setProductSelect] = useState([])
    const [productError, setProductError] = useState('')
    const currentTab = useSelector(state => state?.forms.currentFormTab)

    const rep = useSelector(state => state?.auth.user)
    const {register, errors, setError, handleSubmit, reset, setValue} = useForm({
        resolver: yupResolver(schema),
        // mode:"onBlur",
        reValidateMode: 'onChange',
        defaultValues
    })

    const onLoad = () => {
        if (isEmployeeLoaded) {
            let localStorageUser = localStorage.getItem(LS_KEY_CREATE_CUSTOMER)
            if (localStorageUser) {
                // Delete it
                localStorage.removeItem(LS_KEY_CREATE_CUSTOMER)
                localStorageUser = JSON.parse(localStorageUser)

                const tempDefaultValues = {
                    firstName: localStorageUser?.firstname ?? '',
                    lastName: localStorageUser?.lastname ?? '',
                    nickName: localStorageUser?.nickName ?? '',
                    email: localStorageUser?.email ?? '',
                    phone: localStorageUser?.phone ?? '',
                    gmail: localStorageUser?.gmail ?? '',
                    gitName: localStorageUser?.gitName ?? '',
                    address: localStorageUser?.address ?? '',
                    wechat: localStorageUser?.wechat ?? '',
                    city: localStorageUser?.city ?? '',
                    country: localStorageUser?.country ?? '',
                    zipcode: localStorageUser?.postal ?? '',
                    class: '',
                    regSource: localStorageUser?.source ?? '',
                    sales: localStorageUser?.repId ?? '',
                    repName: localStorageUser?.repName ?? null,
                    notes: localStorageUser?.notes ?? []
                }

                setSalesId(currentTab === 0 ? localStorageUser?.repId ?? '' : rep?.id ?? '')
                const foundUser = customersAndEmployees.find(
                    e => e.id === parseInt(currentTab === 0 ? localStorageUser?.repId : rep?.id, 10)
                )
                handleAutoCompleteChange(null, foundUser)
                setTempAddress(localStorageUser?.address || '')
                setTempResume(localStorageUser?.resume)
                // setDefaultValues(tempDefaultValues)
                reset(tempDefaultValues)
            }

            if (allUserRoles.length <= 0) {
                fetchAllRoles()(dispatch)
            }

            setIsFormDataLoaded(true)
        }
    }
    useEffect(onLoad, [customersAndEmployees])
    useEffect(() => {
        if (isEmployeeLoaded && isFormDataLoaded) {
            setIsLoaded(true)
        }
    }, [isEmployeeLoaded, isFormDataLoaded, isLoaded])

    const handleRedirect = id => {
        if (id) {
            navigate(`/app/customer/registered/check/${id}`)
        }
    }

    const handleSaveSubmit = async formData => {
        let regInfo = ''
        if (productSelect) {
            productSelect.forEach(value => {
                regInfo = regInfo.concat(value, '|')
                return null
            })
        }

        const tempCustomer = {...formData, regInfo}

        if (!tempCustomer.isStaff && !tempCustomer.isCustomer) {
            return setError('category', {type: 'manual', message: 'At least one is required'})
        }

        // User Role
        tempCustomer.roleIds = selectedCat
        if (!tempCustomer?.zipcode) {
            delete tempCustomer.zipcode
        }
        if (!tempCustomer?.wechat) {
            delete tempCustomer.wechat
        }

        if (tempResume) {
            tempCustomer.resume = tempResume
        }
        // validate rep &&class v4 submission
        if (salesId) {
            setRepErrors('')
            tempCustomer.sales = salesId
            if (productSelect.length <= 3 && productSelect.length >= 1) {
                setIsSaving(true)
                const id = await addCustomer(tempCustomer, afterSaving, catchError)(dispatch)
                handleRedirect(id)
            } else setProductError('Must select 1-3 product')
        } else {
            setRepErrors('This field must be selected')
        }
        return () => {}
    }

    const catchError = msg => {
        setIsSaving(false)
        sendErrorMessage(dispatch, msg)
    }

    const afterSaving = () => {
        setIsSaving(false)
    }

    useEffect(() => {
        fetchCustomerProducts()(dispatch)
        fetchEmployees()(dispatch)
    }, [fetchCustomerProducts, fetchEmployees])

    useEffect(() => {
        if (employees) {
            const employeesAndCustomers = []
            employees.forEach(employee => {
                const NameString = `${employee.id}. ${employee.firstName} ${employee.lastName}`
                if (employee.isActive) {
                    employeesAndCustomers.push({NameString, id: employee.id})
                }
            })
            setCustomerAndEmployees(employeesAndCustomers)
            setIsEmployeeLoaded(true)
        }
    }, [employees])

    const handleAutoCompleteChange = (e, v) => {
        if (v) {
            setSalesId(v.id)
            setRepInput(v.NameString)
            setRepErrors('')
        } else {
            setSalesId('')
            setRepErrors('This field must be selected')
        }
    }

    const handleAddressCallback = value => {
        setValue('city', value.city)
        setValue('country', value.country)
    }

    const handleChange = event => {
        setProductSelect(event.target.value)
        if (event.target.value.length <= 3 && event.target.value.length >= 1) {
            setProductError('')
        }
    }

    /*  const onCopy=(name)=>{
        const value= getValues(name);
        if(value){
        navigator.clipboard.writeText(value).then(
            ()=>{sendSuccessMessage(dispatch,`${name} ${value} added to clipboard`)}
        ).catch(
            ()=>{
              sendErrorMessage(dispatch,`${name} ${value} cannot be copied `)
            }
        )}
        else{
          sendErrorMessage(dispatch,`Null ${name} cannot be copied `)
        }
      } */

    const changeHandler = debounce(val => {
        // if(!validateEmail(val)){
        //   dispatch({type: customerActionsType.ValidEmailError, payload: 'email must be a valid email'})
        // }
        if (!val || !validateEmail(val)) {
            dispatch({type: customerActionsType.ContinueCreate})
        }
        if (val && validateEmail(val)) {
            actEmailCheck(val)(dispatch)
        }
    }, 500)

    return (
        <Grid container spacing={2}>
            {isSaving ? <WaitingOnly /> : null}
            {isLoaded && (
                <Grid item>
                    <Paper className={classes.paper__form}>
                        <form noValidate onSubmit={handleSubmit(handleSaveSubmit)}>
                            <Grid container spacing={1} justifyContent="space-between">
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        margin="normal"
                                        name="company"
                                        id="company"
                                        label="From"
                                        inputProps={{readOnly: true, style: {cursor: 'default'}}}
                                        className={classes.text}
                                        defaultValue={ORGANIZATION.name}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            className={classes.repSelector}
                                            noOptionsText="There are no matching employees and customers"
                                            options={customersAndEmployees}
                                            getOptionLabel={option => option.NameString}
                                            inputValue={repInput}
                                            onInputChange={(e, v) => {
                                                if (e && e.type !== 'blur') {
                                                    setRepInput(v)
                                                }
                                            }}
                                            onChange={(e, v) => {
                                                handleAutoCompleteChange(e, v)
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    size="small"
                                                    {...params}
                                                    text="Select a customer or Employees..."
                                                    label="Rep*"
                                                    InputLabelProps={{shrink: true}}
                                                />
                                            )}
                                        />
                                        <FormHelperText error={repErrors !== ''}>
                                            {repErrors !== '' ? repErrors : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justifyContent="space-between">
                                <TextField
                                    label="hidden"
                                    name="regSource"
                                    inputRef={register}
                                    className={classes.hidden}
                                />
                                <TextField label="hidden" name="sales" inputRef={register} className={classes.hidden} />
                                {textField.map((item, index) => {
                                    if (item.name === 'address') {
                                        return (
                                            <Grid item xs={12} sm={6} key={index}>
                                                <AddressAutoComplete
                                                    label={`${item.label} *`}
                                                    className={classes.text}
                                                    name={item.name}
                                                    helperText={errors[item.name]?.message}
                                                    error={Boolean(errors[item.name]?.message)}
                                                    TextComponent={TextField}
                                                    id={item.name}
                                                    handleAddressCallBack={handleAddressCallback}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    currentAddress={tempAddress}
                                                    register={register}
                                                />
                                                {readOnly[index] ? null : (
                                                    <span>
                                                        <Button type="submit">
                                                            <CheckCircle />
                                                        </Button>
                                                        <Button>
                                                            <XCircle />
                                                        </Button>
                                                    </span>
                                                )}
                                            </Grid>
                                        )
                                    }
                                    if (item.name === 'email') {
                                        return (
                                            <Grid item xs={12} sm={6} key={index}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    margin="normal"
                                                    name={item.name}
                                                    id={item.name}
                                                    label={`${item.label} *`}
                                                    autoComplete="off"
                                                    error={Boolean(errors[item.name]?.message)}
                                                    helperText={errors[item.name]?.message}
                                                    className={classes.text}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    inputRef={register}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {isSearching ? <CircularProgress size="20px" /> : ''}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={e => changeHandler(e.target.value)}
                                                />
                                                {/* {readOnly[index] ? null : <span><Button type='submit'><CheckCircle/></Button><Button><XCircle/></Button></span>} */}
                                                <span style={{color: '#F44336', fontSize: '12px'}}>{error}</span>
                                            </Grid>
                                        )
                                    }
                                    return (
                                        <Grid item xs={12} sm={6} key={index}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                margin="normal"
                                                name={item.name}
                                                id={item.name}
                                                label={
                                                    item.name === 'gmail' ||
                                                    item.name === 'gitName' ||
                                                    item.name === 'zipcode' ||
                                                    item.name === 'wechat'
                                                        ? `${item.label} `
                                                        : `${item.label} *`
                                                }
                                                autoComplete="off"
                                                inputRef={register}
                                                error={Boolean(errors[item.name]?.message)}
                                                helperText={errors[item.name]?.message}
                                                className={classes.text}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                            {readOnly[index] ? null : (
                                                <span>
                                                    <Button type="submit">
                                                        <CheckCircle />
                                                    </Button>
                                                    <Button>
                                                        <XCircle />
                                                    </Button>
                                                </span>
                                            )}
                                        </Grid>
                                    )
                                })}

                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth className={classes.categorySelector}>
                                        <InputLabel>Category</InputLabel>
                                        <Select
                                            size="small"
                                            multiple
                                            value={selectedCat}
                                            onChange={e => {
                                                setSelectedCat(e.target.value)
                                            }}
                                            renderValue={selected => (
                                                <div className={classes.chips}>
                                                    {selected.map((value, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={
                                                                allUserRoles.find(
                                                                    role => role.id.toString() === value.toString()
                                                                )?.name || 'Unknown'
                                                            }
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        >
                                            {allUserRoles.map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth className={classes.categorySelector}>
                                        <InputLabel>Please Select Products</InputLabel>
                                        <Select
                                            size="small"
                                            multiple
                                            value={productSelect}
                                            renderValue={selected => (
                                                <div className={classes.chips}>
                                                    {selected.map((value, index) => (
                                                        <Chip key={index} label={value} className={classes.chip} />
                                                    ))}
                                                </div>
                                            )}
                                            onChange={handleChange}
                                            inputRef={register}
                                            name="class"
                                            id="class"
                                        >
                                            {products.map((item, index) => (
                                                <MenuItem key={index} value={item.name}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText error={Boolean(productError)}>{productError}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                                        <FormLabel
                                            component="legend"
                                            required
                                            error={Boolean(errors.category?.message)}
                                        >
                                            User Category
                                        </FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox name="isStaff" inputRef={register} />}
                                                label="Staff"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox name="isCustomer" defaultChecked inputRef={register} />
                                                }
                                                label="Customer"
                                            />
                                        </FormGroup>
                                        <FormHelperText error={Boolean(errors.category?.message)}>
                                            {errors.category?.message
                                                ? errors.category?.message
                                                : 'At least one is required'}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                                {/* note */}
                                {noteField.map((item, index) => (
                                    <Grid item xs={12} sm={12} key={index}>
                                        <FormControl fullWidth>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                margin="normal"
                                                name={item.name}
                                                id={item.name}
                                                label={`${item.label}`}
                                                autoComplete="off"
                                                inputRef={register}
                                                error={Boolean(errors[item.name]?.message)}
                                                helperText={errors[item.name]?.message}
                                                className={classes.text}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                ))}
                            </Grid>
                            {/* <FormGroup row style={{ margin: '0 10px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} >Please select products</Grid>
                {products.map((item, index) => {
                  return (
                    <Grid item xs={12} md={6} key={index}>
                      <FormControlLabel control={
                        <Checkbox name='class' color="primary" value={item.name}
                                  inputRef={register}/>
                      } label={item.name}  onClick={() => handleClick(index)}/>
                    </Grid>
                  );
                })}
              </Grid>
            </FormGroup>
            <FormHelperText error={Boolean(errors.class?.message)}>
              {errors.class?.message}
            </FormHelperText>
*/}
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <SubmitButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        isSaving={isSaving}
                                        text="Save"
                                        isSavingText="Saving"
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <SubmitButton
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        isSaving={isSaving}
                                        text="Cancel"
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            )}
        </Grid>
    )
}

// const mapStateToProps = (state) => ({
//     sales: state.auth.user,
//     products: state.customer.product ? state.customer.product.filter(pr => pr.isActive) : [],
//     error: state.customer?.error,
//     isSearching: state.customer?.isSearching,
//     employees: state.employee?.employees,
//     allUserRoles: state.customer.allUserRoles
// });
//
// export default connect(mapStateToProps, {
//     addCustomer, fetchCustomerProducts, fetchEmployees, actEmailCheck
// })(AddCustomer);
export default AddCustomer
